* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

p {
  font-family: 'clotherRegular';
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar, div::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
body, html {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  max-width: 100%;
  overflow: -moz-scrollbars-none;
  overflow-x: hidden !important;
}

@font-face {
  font-family: "ClotherBold";
  src: url("./assets/fonts/ClotherBold.ttf");
}

@font-face {
  font-family: "ClotherRegular";
  src: url("./assets/fonts/ClotherRegular.ttf");
}

@font-face {
  font-family: "ClotherLight";
  src: url("./assets/fonts/ClotherLight.ttf");
}

@font-face {
  font-family: "NotoSerif";
  src: url("./assets//fonts/NotoSerif-Regular.ttf");
}

@font-face {
  font-family: "NotoSans";
  src: url("./assets//fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "NotoRegular";
  src: url("./assets/fonts/NotoSerifRegular.ttf");
}

.width--100 {
  width: 100%;
}

.font-family--clotherBold {
  font-family: clotherBold;
}

.font-family--montserrat {
  font-family: 'Montserrat', sans-serif;

}

.font-family--clotherLight {
  font-family: ClotherLight;
}

.font-family--clotherRegular {
  font-family: ClotherRegular;
}

.font-family--notoSerif {
  font-family: ClotherRegular;
}

.font-size__16 {
  font-size: 20px;
}

.font-size__17 {
  font-size: 20px;
}

.font-size__20 {
  font-size: 20px;
}

.font-size__22 {
  font-size: 22px;
}

.font-size__24 {
  font-size: 24px;
}

.font-size__26 {
  font-size: 26px;
}

.font-size__30 {
  font-size: 30px;
}

.font-size__31 {
  font-size: 31px;
}

.font-size__32 {
  font-size: 32px;
}

.font-size__45 {
  font-size: 45px;
}

.font-size__51 {
  font-size: 51px;
}

.font-size__53 {
  font-size: 53px;
}

.font-size__65 {
  font-size: 65px;
}

.font-size__120 {
  font-size: 120px;
}

.font-size__700 {
  font-size: 700%;
}

.font-weight__bold {
  font-weight: bold
}

.font-weight__bolder {
  font-weight: bolder
}

.font-weight__lighter {
  font-weight: lighter
}

.backgroundColor--black {
  background-color: #18191a;
}

.backgroundColor--white {
  background-color: #fff;
}

.color--white {
  color: #fff;
}

.color--black {
  color: #000;
}

.color--grey {
  color: grey;
}

.textColor--grey {
  color: #E2E2E2;
}

.padding--5 {
  padding: 5%;
}

.padding--5-0-5-6 {
  padding: 5% 0% 5% 6%;
}

.p--paddingBottom-4 {
  padding-bottom: 4%;
}

.p--paddingBottom-17 {
  padding-bottom: 17.5%;
}

.padding--top__10{
  padding-top: 10%;
}

.padding--top__15{
  padding-top: 15%;
}

.padding--bottom__15{
  padding-bottom: 15%;
}

.link:hover {
  color: #000;
}
.link__white:hover {
  color: #fff;
}

.line-height__2 {
  line-height: 2;
}

.line-height__13 {
  line-height: 1.3;
}

.line-height__11 {
  line-height: 1.1;
}

.line-height__half {
  line-height: 0.8;
}

.line-height__05 {
  line-height: 0.5;
}

.line-height__18 {
  line-height: 1.8;
}

.line-height__19 {
  line-height: 1.9;
}

.line-height__20 {
  line-height: 2;
}

.paddingRight--10 {
  padding-right: 10%;
}

.paddingRight--60 {
  padding-right: 60%;
}

.paddingRight--14 {
  padding-right: 14%;
}

.paddingLeft--6 {
  padding-left: 6%;
}

.marginLeft--6 {
  margin-left: 6%;
}

/* common */

.clother--bold {
  font-family: "clotherBold"
}

.noto--regular {
  font-family: "NotoRegular"
}

.noto--sans {
  font-family: "clotherRegular"
}

.noto--regular_imp {
  font-family: "NotoRegular" !important
}

.clother--light {
  font-family: "clotherLight"
}

.clother--regular {
  font-family: "clotherRegular"
}

.no-padding {
  padding: 0 !important
}

.no-margin {
  margin: 0 !important
}

.mobile {
  display: none !important
}

.non--mobile {
  display: block !important
}

.carousel-control-prev {
  display: none !important;
}

.flex--column {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.flex--row__spaceevenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.common--button {
  width: 200px;
  font-size: 20px;
  opacity: 1;
  height: 59px;
  text-align: center;
}
.common--buttonContainer {
  align-items: center;
  align-content: center;
  padding: 25px;
  padding-left: 0px;
}
.text--decoration {
  text-decoration: none;
}
.bcolor--black{
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}
.bcolor--white{
  background-color: #fff;
  border: 1px solid #fff;

  color: #000;
}
.arrowbutton {
  width: 27px;
  height: 27px;
  transition-timing-function: ease-in;
  transition: 0.2s;
}
.common--buttonContainer:hover .arrowbutton{
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: rotate(45deg);
}
/* Media query */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1000px) and (orientation: portrait) {
  .mobile {
    display: block !important
  }

  .non--mobile {
    display: none !important
  }

  .font-size__16 {
    font-size: 16px;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (orientation:landscape) {
  .common--button {
      font-size: 25px;
      opacity: 1;
      text-align: center;
  }
}