.footer {
    background-color: rgb(24, 25, 26) !important;
    min-height: 100vh;
    color: #fff;
}

.footer--content {
    padding-top: 5%;
    padding-bottom: 2%;
    padding-left: 6%;
    padding-right: 12%;
    letter-spacing: 0px;
    line-height: 1.2;
}

.footer--content--description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6% 0 0;
}

.footer--content--description--1 {
    width: 50%;
    /* line-height: 1.8; */
}

.footer--content--description--1--desc {
    padding-right: 13vw;

}

.footer--content--description--2 {
    width: 28%;
    /* padding-top: 1%; */
}

.footer--content--description--3 {
    width: 22%;
}

.font-size__1 {
    font-size: 1.10vw;
}

.font-size__2 {
    font-size: 2.1vw;
}

.SBBC {
    margin-top: 5%;
    width: 185px;
    height: 185px;
}

.footer--final{
    margin-top: 7vw;
}

.footer--email {
    margin-bottom: 2vw;
}

.footer--email:hover {
    opacity: 0.55;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .Home--title {
        font-size: 300%;
    }

    .SBBC {
        margin-top: 0%;
        width: 135px;
        height: 135px;
        margin-bottom: 5%;
    }

    .footer--content {
        padding-top: 15%;
        padding-bottom: 8%;
        padding-left: 6%;
        padding-right: 7%;
        letter-spacing: 0px;
        line-height: 1.2;
    }

    .footer--email {
        margin-bottom: 5%;
    }

    .footer--content--description {
        display: block;
    }

    .footer--content--description--1, .footer--content--description--2, .footer--content--description--3 {
        width: 100%;
        margin-bottom: 2rem;
    }
    
    .font-size__2 {
        font-size: 8vw;
        display:block;
    }
    .font-size__1 {
        font-size: 4vw;
        display:block;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) {
    .Home--title {
        font-size: 52px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .Home--title {
        font-size: 75px;
    }
    .footer {
        min-height:50vh;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (orientation:landscape) {
    .Home--title {
        font-size: 700%
    }

    .contact--list {
        width: 50%;
    }
}