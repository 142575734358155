
.services--title{
    padding-top: 14.10%;
    padding-bottom: 1%;
    padding-left: 6%;
}
.see--all {
    text-align: center;
    margin-bottom: 5%;
    margin-top: 5% !important;
}
/* .see--all img {
    opacity: 0.6;
    cursor: pointer;
}
.see--all img:hover{
    opacity: 1;
} */
.services--text{
    padding-left: 6%;
    padding-right: 50%;
    padding-bottom: 5.10%;
    line-height: 1.8;
    letter-spacing: 0px;
}
.services-container{
    padding-left: 6%;
    width:85%;
    max-width:85%;
    /* margin-right: 15%; */
}
.content-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Services--Item-title{
    padding-bottom: 4%;
}
.Services--Item-innerText{
    padding-right: 14%;
}
.services-subText{
    padding-right: 23%;
}
.paddingLeft-custom{
    padding-left: 0% !important;
    padding-bottom: 10%;
}
.padding-custom{
    padding: 3% 6% 5%;
}
.Services--Item-hypen{
    width: 4%;
}
.Services--Item-points{
    width: 96%;
}
.services--contact--button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 25vh;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation:portrait){
    .see--all {
        text-align: center;
        margin-bottom: 5%;
        margin-top: 15% !important;
    }
    .services--title{
        font-size: 300%;
        padding-top: 65%;
    } 
    .services--text{
     padding-right: 5%;
     padding-bottom: 23%;
     font-size: 23.5px;
    }
    .Services--Item-title{
        font-size: 300%;
        padding-bottom: 1%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 20%;
    }
    .Services--Item-innerText{
        font-size: 14px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 23%;
    }
    .para--padding{
        padding-bottom: 6%;
    }
    .Services--example{
        font-size: 18px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .services--contact--button{
        margin-bottom: 15vh;
    }
    .Services--Item-hypen{
        font-size: 16px;
        width: 3%;
    }
    .Services--Item-points{
        width: 97%;
        padding-bottom: 3%;
    }
    .Services--Item-points span{
        font-size: 23.5px;
    }
    .see--all img {
        margin-top: 5%;
    }
}
/* iphone portrait*/
@media only screen and (width: 320px) and (height:568px) and (orientation:portrait){
    .services--title{
        font-size: 300%;
        padding-top: 57%;
        padding-bottom: 0%;
    } 
    .services--text{
     padding-right: 0%;
     padding-bottom: 11%;
     font-size: 21.5px;
    }
    .Services--Item-innerText{
        font-size: 12px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-hypen{
        font-size: 12px;
    }
}
/* iphone landscape*/
@media only screen and (min-width: 568px) and (min-height:320px) and (orientation:landscape){
    .services--title{
        font-size: 230%;
        padding-top: 25%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 12%;
     padding-bottom: 5%;
     font-size: 20px;
    }
    .Services--Item-title{
        font-size: 25px;
        padding-bottom: 0%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 5%;
    }
    .Services--Item-innerText{
        font-size: 12px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 18px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 12px;
        width: 2%;
    }
    .Services--Item-points{
        width: 98%;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation:portrait){
    .services--title{
        font-size: 300%;
        padding-top: 57%;
    } 
    .services--text{
     padding-right: 0%;
     padding-bottom: 23%;
     font-size: 23.5px;
    }
    .Services--Item-title{
        font-size: 25px;
        padding-bottom: 1%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 20%;
    }
    .Services--Item-innerText{
        font-size: 14px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--example{
        font-size: 18px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 16px;
        width: 3%;
    }
    .Services--Item-points{
        width: 97%;
    }
    .see--all img {
        margin-top: 10%;
    }
}
/* Surface duo portrait (540px to 720px) */
@media only screen and (min-width: 540px) and (max-height:720px) and (orientation:portrait){
    .services--title{
        font-size: 50px;
        padding-top: 45%;
        margin-bottom: 0%;
    } 
    .services--text{
     padding-right: 10%;
     font-size: 27px;
    }
    .Services--Item-title{
        font-size: 35px;
    }
    .paddingLeft-custom{
        padding-bottom: 15%;
    }
    .Services--Item-innerText{
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-hypen{
        font-size: 15px;
    }
}
/* iphone6/7/8 landscape*/
@media only screen and (min-width: 667px) and (min-height:375px) and (orientation:landscape){
    .services--title{
        font-size: 230%;
        padding-top: 25%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 25%;
     padding-bottom: 8%;
     font-size: 20px;
    }
    .Services--Item-title{
        font-size: 30px;
        padding-bottom: 0%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 13px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 18px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 13px;
        width: 2%;
    }
    .Services--Item-points{
        width: 98%;
    }
}
/* Small devices (landscape) */
@media only screen and (min-width: 600px) and (orientation:landscape){
    .services--title{
        font-size: 36px;
        padding-top: 25%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 28%;
     padding-bottom: 8%;
     font-size: 20px;
    }
    .Services--Item-title{
        font-size: 35px;
        padding-bottom: 0%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 11px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 20px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 11px;
        width: 2%;
    }
    .Services--Item-points{
        width: 98%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:landscape){
    .services--title{
        font-size: 50px;
        padding-top: 32%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 35%;
     padding-bottom: 8%;
     font-size: 20px;
    }
    .Services--Item-title{
        font-size: 35px;
        padding-bottom: 0%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 11px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 5%;
    }
    .Services--example{
        font-size: 20px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 11px;
        width: 3%;
    }
    .Services--Item-points{
        width: 97%;
    }
}
/* iphone x landscape*/
@media only screen and (min-width: 812px) and (min-height:375px) and (orientation:landscape){
    .services--title{
        font-size: 230%;
        padding-top: 22%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 35%;
     padding-bottom: 5%;
     font-size: 20px;
    }
    .Services--Item-title{
        font-size: 28px;
        padding-right: 20%;
        padding-bottom: 0%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 12px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 20px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 12px;
        width: 3%;
    }
    .Services--Item-points{
        width: 97%;
    }
}
/*iphone 12 pro max landscape(926px and 428px) */
@media only screen and (min-width: 926px) and (min-height: 428px) and (orientation:landscape){
    .services--title{
        font-size: 300%;
        padding-top: 20%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 35%;
     padding-bottom: 6%;
     font-size: 22px;
    }
    .Services--Item-title{
        font-size: 28px;
        padding-right: 30%;
        padding-bottom: 0%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 12px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 20px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 12px;
        width: 3%;
    }
    .Services--Item-points{
        width: 97%;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (orientation:landscape) {
    .services--title{
        font-size: 400%;
        padding-top: 21.25%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 35%;
     padding-bottom: 6%;
     font-size: 25px;
    }
    .Services--Item-title{
        font-size: 40px;
        padding-bottom: 0%;
        padding-right: 30%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 12px;
        padding-right: 10%;
    }
    .services-subText{
        padding-right: 20%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 23px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 12px;
    }
}
/* Surface duo landscape (1114px to 720px) */
@media only screen and (min-width: 1114px) and (max-height:720px) and (orientation:landscape){
    .services--title{
        font-size: 450%;
        padding-top: 27%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 45%;
     padding-bottom: 11%;
     font-size: 25px;
    }
    .Services--Item-title{
        font-size: 34px;
        padding-bottom: 0%;
        padding-right: 30%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 14px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 23px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 14px;
    }
}
/* ipad landscape */
@media only screen and (min-width: 1024px) and (min-height:768px) and (orientation:landscape){
    .services--title{
        font-size: 70px;
        padding-top: 29.5%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 35%;
     padding-bottom: 11%;
     font-size: 30px;
    }
    .Services--Item-title{
        font-size: 40px;
        padding-bottom: 0%;
        padding-right: 30%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 13.5px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    .Services--example{
        font-size: 23px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 13.5px;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px)  and (orientation:landscape){
    .services--title{
        padding-top: 16.8%;
        padding-left: 5.7%;
        font-size: 700%;
    }
    .services--text{
        padding-left: 6%;
        padding-right: 50%;
        padding-bottom: 15%;
        line-height: 1.8;
        letter-spacing: 0px;
        font-size: 30px;
    }
    .services-container{
        padding-left: 6%;
        
        margin-right: 0 !important;
        /* margin-right: 15%; */
    }
    .content-flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .Services--Item-title{
        padding-bottom: 4%;
        font-size: 49px;
    }
    .Services--Item-innerText{
        padding-right: 10%;
        font-size: 15px;
        line-height: 2;
    }
    .services-subText{
        padding-right: 20%;
    }
    .Services--Item-description{
        padding-bottom: 4%;
    }
    .Services--Item-points{
        font-size: 16px;
    }
    
    .Services--example{
        font-size: 32px;
        text-align: left;
    }
    .paddingLeft-custom{
        padding-left: 0% !important;
        padding-bottom: 6%;
    }
    .padding-custom{
        padding: 10% 6% 3%;
    }
    .Services--Item-hypen{
        width: 3%;
        margin-top: 0.2rem;
        margin-bottom: 0rem;
        font-size: 16px;
    }
    .Services--Item-points{
        width: 97%;
    }
}
/* ipad pro landscape,1366px and 1024px*/
@media only screen and (min-width: 1366px) and (min-height:1024px) and (orientation:landscape){
    .services--title{
        font-size: 620%;
        padding-top: 25%;
        padding-bottom: 0%;
        margin-bottom: 1%;
    } 
    .services--text{
     padding-right: 35%;
     padding-bottom: 11%;
     font-size: 35px;
    }
    .Services--Item-title{
        font-size: 45px;
        padding-bottom: 0%;
        padding-right: 30%;
    }
    .paddingLeft-custom{
        padding-right: 0% !important;
        padding-bottom: 8%;
    }
    .Services--Item-innerText{
        font-size: 18px;
        padding-right: 15%;
    }
    .services-subText{
        padding-right: 25%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
    }
    
    .Services--example{
        font-size: 25px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
    }
    .Services--Item-hypen{
        font-size: 20px;
    }
}
/*iphone 12 pro max portrait(428px and 926px) */
@media only screen and (min-width: 428px) and (min-height: 926px) and (orientation:portrait){
    .services--title{
        font-size: 300%;
        padding-top: 71%;
        padding-bottom: 1%;
        padding-left: 7%;
    } 
    .services--text{
     padding-right: 8%;
     padding-bottom: 32%;
     font-size: 23.5px;
     padding-left: 8%;
    }
    .Services--Item-title{
        font-size: 32px;
        padding-bottom: 2%;
        padding-left: 0%;
    }
    .Services--Item-innerText{
        font-size: 16px;
        padding-right:10%;
    }
    .services-subText{
        padding-right: 20%;
    }
    .Services--Item-description{
        padding-bottom: 4%;
    }
    .Services--example{
        font-size: 18px;
        text-align: center;
    }
    .services-container{
        width: 100%;
        max-width: 100%;
        margin-right: 0%;
        padding-left: 9%;
        padding-right: 3%;
    }
    .Services--Item-hypen{
        font-size: 16px;
            
    }
}
/*(ipad portrait, 768px and 1024px and up) */
@media only screen and (min-width: 768px) and (min-height:1024px) and (orientation:portrait){
    .services--title{
        font-size: 450%;
        padding-top: 40%;
        padding-bottom: 0%;
    } 
    .services--text{
     padding-right: 14%;
     padding-bottom: 20%;
     font-size: 32px;
    }
    .Services--Item-title{
        font-size: 40px;
        padding-bottom: 0%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
        padding-right: 2%;
    }
    .services--contact--button{
        margin-bottom:10vh;
    }
    .paddingLeft-custom{
        padding-bottom: 10%;
    }
    .Services--Item-innerText{
        font-size: 9.5px;
    }
    .Services--Item-hypen{
        font-size: 12px;
        width: 4%;
    }
    .Services--Item-points {
        width: 96%;
    }
}    
/*(ipad pro portrait, 1024px and 1366px and up) */
@media only screen and (min-width: 1024px) and (min-height:1366px) and (orientation:portrait){
    .services--title{
        font-size: 550%;
        padding-top: 40%;
        padding-bottom: 0%;
    } 
    .services--text{
        padding-right: 20%;
        padding-bottom: 14%;
        font-size: 44px;
    }
    .Services--Item-title{
        font-size: 46px;
        padding-bottom: 0%;
    }
    .Services--Item-description{
        padding-bottom: 0%;
        padding-right: 8%;
    }
    .paddingLeft-custom{
        padding-bottom: 10%;
    }
    .Services--Item-innerText{
        font-size: 13px;
        
    }
    .Services--Item-hypen{
        font-size: 13px;
}
}