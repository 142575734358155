.studio--container {
    /* height:100vh; */
    background-image: url('./../../assets/images/studio.png');
    background-size: cover;
    background-position: center;
    padding-top: 40vh;
    padding-bottom: 27vh;
}

.studio--Item-title {
    margin-top: 5vh;
}

.studio--name-title {
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.studio--shan-description {
    margin-right: 8vw;
}

.studio--twitter--title:hover {
    color: #000;
}

.studio--flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.studio--sub--flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.studio--shan--flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.studio--flex--1 {
    width: 20%;
}

.studio--flex--2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.studio--flex--2--1 {
    width: 36%;
    padding-top: 9%;
}

.studio--flex--2--2 {
    width: 64%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.row--start {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.studio--flex--3--1 {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.studio--flex--3--2 {
    width: 45%;
    padding-top: 9%;
}

.studio--sub--flex--1 {
    width: 30%;
}

.studio--sub--flex--2 {
    width: 70%;
}

.rgd {
    width: 150px;
}

.processing--img {
    width: 100%;
}

.processing--img--shan {
    width: 884px;
    height: 884px;
    margin-left: -10vw;

}

.studio--head__text {
    margin-bottom: 5vw;
}

.studio--head {
    margin-right: 10vw;
}

.studio--role {
    margin: 5px
}

.studio--details {
    margin-top: 52%;
    margin-Bottom: 90%;
}

.studio--family {
    margin-top: 7% !important;
}

.studio--image__animated {
    position: absolute;
    z-index: -1;
    margin-top: 10%;
    transition: 200ms ease-out all;
    transition-timing-function: ease-out
}

.studio--page {
    /* margin-bottom: 10%; */
    overflow: hidden;
}

.mobile{
    display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .padding--5 {
        padding:7%;
    }

    .studio--container {
        padding-top: 63.3%;
        background-image: url('./../../assets/images/studio-mobile.png');
    }

    .studio--head__text {
        font-size: 320%;
        margin-bottom: 5vw;
    }

    .line-height__18 {
        line-height: 1.5
    }

    .noto--regular {
        line-height: 1.9;
        margin-top: 3%;
    }

    .studio--flex {
        display: block;
    }

    .studio--Item-title {
        margin-top: 8vh !important;
        margin-bottom: 5vh;
    }

    .studio--flex--1 {
        width: 100%;
    }

    .studio--flex--2 {
        width: 100%;
    }

    .studio--flex--2--1 ,.studio--flex--3--1,.studio--flex--3--2{
        width: 100%;
    }

    .studio--flex--2--2 {
        width: 100%;
    }

    .studio--sub--flex--1 {
        width: 50%;
    }

    .studio--sub--flex--2 {
        width: 50%;
    }

    .processing--img{
        margin: 5% 0;
    }
    .processing--img--shan {
        width: 100%;
        height: 100%;
        margin-left: 0vw;
    
    }
    .studio--shan--flex{
        display: block;
        flex-direction: row-reverse;
        margin-bottom: 10vh;
    }
    .font-size__120 {
        font-size: 320%;
    }

    .font-size__30 {
        font-size: 23.5px;
    }

    .studio--role {
        margin: 0;
        margin-top: 3%;
        font-size: 195%;
    }

    .studio--details {
        margin-top: 15%;
    }

    .studio--details .noto--regular {
        font-size: 101%;
        line-height: 2
    }

    .studio--details .studio--role:nth-child(2) {
        margin-top: 10%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) {
    .studio--container {
        background-image: url('./../../assets/images/studio-mobile.png');
    }

    .studio--head__text {
        font-size: 320%;
        margin-bottom: 5vw;
        margin-top: 17vw;
    }

    .line-height__18 {
        line-height: 1.5
    }

    .noto--regular {
        line-height: 1.9;
        margin-top: 3%;
    }

    .font-size__120 {
        font-size: 320%;
    }

    .studio--role {
        margin: 0;
        margin-top: 3%;
        font-size: 195%;
    }

    .studio--details {
        margin-top: 15%;
    }

    .studio--details .noto--regular {
        font-size: 101%;
        line-height: 2
    }

    .studio--details .studio--role:nth-child(2) {
        margin-top: 10%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .studio--container {
        padding-top: 15vh;
        background-image: url('./../../assets/images/studio.png');
    }

    .studio--head__text {
        font-size: 320%;
        margin-bottom: 5vw;
        margin-top: 10vw;
    }

    .studio--family {
        margin-bottom: 2%;
    }

    .line-height__18 {
        line-height: 1.5
    }

    .noto--regular {
        line-height: 1.9;
        margin-top: 3%;
        font-size: 160%;
    }

    .font-size__120 {
        font-size: 450%;
    }

    .studio--role {
        margin: 0;
        margin-top: 3%;
        font-size: 255%;
    }

    .studio--details {
        margin-top: 10%;
        margin-bottom: 70%;
    }

    .studio--details .noto--regular {
        font-size: 160%;
        line-height: 2
    }

    .studio--details .studio--role:nth-child(2) {
        margin-top: 2%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 1200px) {
    .studio--container {
        padding-top: 31vh;
        padding-bottom: 35vh;
    }

    .studio--container p:nth-child(1) {
        /* margin-bottom: 0px; */
    }

    .studio--head__text {
        font-size: 320%;
        margin-bottom: 2vw;
        margin-top: 0vw;
    }

    .studio--container .noto--regular {
        font-size: 95%
    }

    .studio--family {
        margin-bottom: 10%;
    }

    .line-height__18 {
        line-height: 1.5
    }

    .noto--regular {
        line-height: 1.9;
        margin-top: 3%;
    }

    .font-size__120 {
        font-size: 110px;
    }

    .studio--role {
        margin: 0;
        margin-top: 1%;
        font-size: 32px;
    }

    .studio--details {
        margin-top: 55%;
    }

    #reggae .studio--details {
        margin-top: 35%;
    }

    #reggae .font-size__120 {
        margin-left: -0.6vw !important;
    }

    .studio--details .noto--regular {
        font-size: 95%;
        line-height: 2;
        margin-bottom: 1%;
    }

    .studio--details .studio--role:nth-child(2) {
        margin-top: 0%;
    }
}

@media only screen and (min-width: 1900px) {
    .processing--img--shan {
        width: 1920px;
        height: 1084px;
        margin-left: -10vw;
    }
}