.cover {
    width: 100%;
    height: 100%;
}

.menu--icon {
    padding: 20px;
    background-color: #fff; 
    border-radius: 100%;
    width:fit-content;
    position: fixed; 
    z-index:15;
    cursor: pointer;
    top:70px;
    right:70px;
    animation: fadeIn 0.3s;
    animation: growOut 0.2s;
}

.menu--list a {
    all: unset;
}

.dark--icon {
    padding: 20px;
    background-color: #18191A; 
    border-radius: 100%;
    border-color: #18191A;
    width:fit-content;
    position: fixed; 
    z-index:15;
    cursor: pointer;
    top:70px;
    right:70px;
    /* animation: growOut 0.2s; */
}

.menu--icon_active {
    padding: 1600px;
    position: fixed; 
    border-radius: 100%;
    background-color: #fff;
    z-index:8;
    top: -1510px;
    right: -1510px;
    animation: reveal 0.8s;
    animation-timing-function:linear
}

.menu--icon_inactive {
    padding: 25px;
    position: fixed; 
    border-radius: 100%;
    background-color: #fff;
    z-index:8;
    top: 65px;
    right: 65px;
    animation: hide 0.8s;
    animation-timing-function:linear
}

.menu--content {
    position: fixed;
    z-index: 12;
    height: 100vh;
    width:100vw;
    top:0px;
    padding:6.5%;
    left: 0px;
}

.menu--list {
    position: absolute;
    bottom: 2%;
}

.menu--item {
    padding-bottom: 7%;
    cursor: pointer;
}

.menu--item:hover p{
    color:#959595
}

.menu--item p {
    font-weight: 500;
    font-size: 650%;
}

@keyframes reveal {
    0% {
        padding: 25px;
        top: 85px;
        left: 85px;
    }
    100% {
        padding: 1600px;
        top: -1510px;
        left: -1510px;
    }
} 

@keyframes hide {
    0% {
        padding: 1600px;
        top: -1510px;
        left: -1510px;
    }
    100% {
        padding: 25px;
        top: 85px;
        left: 85px;
    }
} 

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation:portrait) {
    .menu--icon {
        padding: 18px;
        background-color: #fff; 
        border-radius: 100%;
        width:64px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top: 40px;
        right: 40px;
    }

    .dark--icon {
        padding: 18px;
        background-color: #18191A; 
        border-radius: 100%;
        width:64px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:40px;
        right:40px;
    }

    .menu--icon_active {
        padding: 1000px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -940px;
        right: -940px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 18px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 55px;
        right: 55px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:8%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 35%;
    }
    
    .menu--item {
        padding-bottom: 25%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 300%;
    }
    
    @keyframes reveal {
        
        0% {
            padding: 18px;
            top: 55px;
            right: 55px;
        }
        45% {
            padding: 450px;
            top: -390px;
            right: -390px;
        }
        100% {
            padding: 1000px;
            top: -940px;
            right: -940px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1000px;
            top: -940px;
            right: -940px;
        }
        100% {
            padding: 18px;
            top: 55px;
            right: 55px;
        }
    } 
    
   
}

/* Extra small devices  landscape */
@media only screen and (max-width: 600px) and (orientation:landscape) {
    .menu--icon {
        padding: 0px;
        border: 15px solid white;
        background-color: #fff; 
        border-radius: 100%;
        width:58px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
    }

    .dark--icon {
        padding: 15px;
        background-color: #18191A; 
        border-radius: 100%;
        width:58px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
    }

    .menu--icon_active {
        padding: 1000px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -950px;
        right: -950px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 15px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 40px;
        right: 40px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:7.5%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 35%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 230%;
    }

    @keyframes reveal {
        
        0% {
            padding: 15px;
            top: 35px;
            right: 35px;
        }
        45% {
            padding: 450px;
            top: -400px;
            right: -400px;
        }
        100% {
            padding: 1000px;
            top: -950px;
            right: -950px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1000px;
            top: -950px;
            right: -950px;
        }
        100% {
            padding: 15px;
            top: 35px;
            right: 35px;
        }
    } 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation:portrait) {
    .menu--icon {
        padding: 18px;
        background-color: #fff; 
        border-radius: 100%;
        width:64px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:40px;
        right:40px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 18px;
        width:64px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:40px;
        right:40px;
    }

    .menu--icon_active {
        padding: 1000px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -940px;
        right: -940px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 18px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 50px;
        right: 50px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:9%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 35%;
    }
    
    .menu--item {
        padding-bottom: 25%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 300%;
    }

    .menu--icon:hover {
        padding: 22px;
        top: 36px;
        right: 36px;
        animation: grow 0.2s
    }

    @keyframes reveal {
        
        0% {
            padding: 18px;
            top: 40px;
            right: 40px;
        }
        45% {
            padding: 450px;
            top: -390px;
            right: -390px;
        }
        100% {
            padding: 1000px;
            top: -940px;
            right: -940px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1000px;
            top: -940px;
            right: -940px;
        }
        100% {
            padding: 18px;
            top: 40px;
            right: 40px;
        }
    } 

    @keyframes grow {
        0% {
            padding:18px;
            top:40px;
            right:40px;
        }
        100% {
            padding: 22px;
            top: 36px;
            right: 36px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 22px;
            top: 36px;
            right: 36px;
        }
        100% {
            padding:18px;
            top:40px;
            right:40px;
        }
    }
    
}

/* Small devices (landscape) */
@media only screen and (min-width: 600px) and (orientation:landscape) {
    .menu--icon {
        padding: 15px;
        background-color: #fff; 
        border-radius: 100%;
        width:58px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
    }

    .dark--icon {
        padding: 15px;
        background-color: #18191A; 
        border-radius: 100%;
        width:58px;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
    }

    .menu--icon_active {
        padding: 1000px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -950px;
        right: -950px;
        animation: reveal 0.5s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 15px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 40px;
        right: 40px;
        animation: hide 0.5s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:6.5%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 35%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 230%;
    }

    @keyframes reveal {
        
        0% {
            padding: 15px;
            top: 40px;
            right: 40px;
        }
        45% {
            padding: 450px;
            top: -400px;
            right: -400px;
        }
        100% {
            padding: 1000px;
            top: -950px;
            right: -950px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1000px;
            top: -950px;
            right: -950px;
        }
        100% {
            padding: 15px;
            top: 40px;
            right: 40px;
        }
    } 
    
}

/* Surface Duo portrait */
@media only screen and (min-width: 540px) and (min-height: 720px) and (orientation:portrait) {
    .menu--icon {
        padding: 18px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:40px;
        right:40px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 18px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:40px;
        right:40px;
    }

    .menu--icon_active {
        padding: 1500px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -1440px;
        right: -1440px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 18px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 50px;
        right: 50px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:7%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 55%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 300%;
    }

    .menu--icon:hover {
        padding: 22px;
        top: 36px;
        right: 36px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        
        0% {
            padding: 18px;
            top: 50px;
            right: 50px;
        }
        45% {
            padding: 450px;
            top: -390px;
            right: -390px;
        }
        100% {
            padding: 1500px;
            top: -1440px;
            right: -1440px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1500px;
            top: -1440px;
            right: -1440px;
        }
        100% {
            padding: 18px;
            top: 50px;
            right: 50px;
        }
    } 
    
    @keyframes grow {
        0% {
            padding:18px;
            top:40px;
            right:40px;
        }
        100% {
            padding: 22px;
            top: 36px;
            right: 36px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 22px;
            top: 36px;
            right: 36px;
        }
        100% {
            padding:18px;
            top:40px;
            right:40px;
        }
    }
}

/* Surface Duo landscape */
@media only screen and (min-width: 720px) and (min-height: 540px) and (orientation:landscape) {
    .menu--icon {
        padding: 15px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 15px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
    }

    .menu--icon_active {
        padding: 1000px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -950px;
        right: -950px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 15px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 40px;
        right: 40px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:7%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 55%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 230%;
    }

    .menu--icon:hover {
        padding: 18px;
        top: 32px;
        right: 32px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        
        0% {
            padding: 15px;
            top: 40px;
            right: 40px;
        }
        45% {
            padding: 450px;
            top: -400px;
            right: -400px;
        }
        100% {
            padding: 1000px;
            top: -950px;
            right: -950px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1000px;
            top: -950px;
            right: -950px;
        }
        100% {
            padding: 15px;
            top: 40px;
            right: 40px;
        }
    } 
    
    @keyframes grow {
        0% {
            padding:15px;
            top:35px;
            right:35px;
        }
        100% {
            padding: 18px;
            top: 32px;
            right: 32px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 18px;
            top: 32px;
            right: 32px;
        }
        100% {
            padding:15px;
            top:35px;
            right:35px;
        }
    }
}

/* iPad, Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:portrait) {
    .menu--icon {
        padding: 20px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:60px;
        right:60px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 20px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:60px;
        right:60px;
    }

    .menu--icon_active {
        padding: 1400px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -1320px;
        right: -1320px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 18px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 70px;
        right: 70px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:7%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 32%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 450%;
    }

    .menu--icon:hover {
        padding: 25px;
        top: 55px;
        right: 55px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        
        0% {
            padding: 20px;
            top: 70px;
            right: 70px;
        }
        45% {
            padding: 450px;
            top: -370px;
            right: -370px;
        }
        100% {
            padding: 1400px;
            top: -1320px;
            right: -1320px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1400px;
            top: -1320px;
            right: -1320px;
        }
        100% {
            padding: 20px;
            top: 60px;
            right: 60px;
        }
    } 
    
    @keyframes grow {
        0% {
            padding:20px;
            top:60px;
            right:60px;
        }
        100% {
            padding: 25px;
            top: 55px;
            right: 55px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 25px;
            top: 55px;
            right: 55px;
        }
        100% {
            padding:20px;
            top:60px;
            right:60px;
        }
    }
} 

/* Mobile phone landscape */
@media only screen and (min-width: 768px) and (orientation:landscape) {
    .menu--icon {
        padding: 15px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 15px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:35px;
        right:35px;
    }

    .menu--icon_active {
        padding: 1500px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -1450px;
        right: -1450px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 15px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 40px;
        right: 40px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:5%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 35%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 230%;
    }

    .menu--icon:hover {
        padding: 18px;
        top: 32px;
        right: 32px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        
        0% {
            padding: 15px;
            top: 40px;
            right: 40px;
        }
        45% {
            padding: 450px;
            top: -400px;
            right: -400px;
        }
        100% {
            padding: 1500px;
            top: -1450px;
            right: -1450px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1500px;
            top: -1450px;
            right: -1450px;
        }
        100% {
            padding: 15px;
            top: 40px;
            right: 40px;
        }
    } 
    
    @keyframes grow {
        0% {
            padding:15px;
            top:35px;
            right:35px;
        }
        100% {
            padding: 18px;
            top: 32px;
            right: 32px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 18px;
            top: 32px;
            right: 32px;
        }
        100% {
            padding:15px;
            top:35px;
            right:35px;
        }
    }
} 

/* iPad pro portrait */
@media only screen and (min-width: 992px) and (orientation:portrait) {
    .menu--icon {
        padding: 20px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:60px;
        right:60px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 20px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:60px;
        right:60px;
    }

    .menu--icon_active {
        padding: 2100px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -2020px;
        right: -2020px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 18px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 70px;
        right: 70px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:7%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 32%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 550%;
    }

    .menu--icon:hover {
        padding: 25px;
        top: 55px;
        right: 55px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        
        0% {
            padding: 20px;
            top: 70px;
            right: 70px;
        }
        45% {
            padding: 450px;
            top: -370px;
            right: -370px;
        }
        100% {
            padding:2100px;
            top: -2020px;
            right: -2020px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 2100px;
            top: -2020px;
            right: -2020px;
        }
        100% {
            padding: 20px;
            top: 60px;
            right: 60px;
        }
    } 
    
    @keyframes grow {
        0% {
            padding:20px;
            top:60px;
            right:60px;
        }
        100% {
            padding: 25px;
            top: 55px;
            right: 55px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 25px;
            top: 55px;
            right: 55px;
        }
        100% {
            padding:20px;
            top:60px;
            right:60px;
        }
    }
}

/* iPad, iPad pro landscape */
@media only screen and (min-width: 992px) and (orientation:landscape){
    .menu--icon {
        padding: 20px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:60px;
        right:60px;
        animation: growOut 0.2s
    }

    .dark--icon {
        padding: 20px;
        background-color: #18191A; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:60px;
        right:60px;
    }

    .menu--icon_active {
        padding: 1800px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -1720px;
        right: -1720px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 18px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 70px;
        right: 70px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:7%;
        animation: fadeIn 0.5s;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 42.5%;
    }
    
    .menu--item {
        padding-bottom: 15%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 70px;
    }

    .menu--icon:hover {
        padding: 25px;
        top: 55px;
        right: 55px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        
        0% {
            padding: 20px;
            top: 70px;
            right: 70px;
        }
        45% {
            padding: 450px;
            top: -370px;
            right: -370px;
        }
        100% {
            padding: 1800px;
            top: -1720px;
            right: -1720px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 1800px;
            top: -1720px;
            right: -1720px;
        }
        100% {
            padding: 20px;
            top: 60px;
            right: 60px;
        }
    } 
    
    @keyframes grow {
        0% {
            padding:20px;
            top:60px;
            right:60px;
        }
        100% {
            padding: 25px;
            top: 55px;
            right: 55px;
        }
    }
    
    @keyframes growOut {
        0% {
            padding: 25px;
            top: 55px;
            right: 55px;
        }
        100% {
            padding:20px;
            top:60px;
            right:60px;
        }
    }
}

/* laptops */
@media only screen and (min-width: 1200px) and (orientation:landscape) {
    .menu--icon {
        padding: 20px;
        background-color: #fff; 
        border-radius: 100%;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:70px;
        right:70px;
        animation: fadeIn 0.3s;
        animation: growOut 0.2s;
    }
    
    .dark--icon {
        padding: 20px;
        background-color: #18191A; 
        border-radius: 100%;
        border-color: #18191A;
        width:fit-content;
        position: fixed; 
        z-index:15;
        cursor: pointer;
        top:70px;
        right:70px;
        /* animation: growOut 0.2s; */
    }
    
    .menu--icon_active {
        padding: 2100px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: -2010px;
        right: -2010px;
        animation: reveal 0.8s;
        animation-timing-function:linear
    }
    
    .menu--icon_inactive {
        padding: 25px;
        position: fixed; 
        border-radius: 100%;
        background-color: #fff;
        z-index:8;
        top: 85px;
        right: 85px;
        animation: hide 0.8s;
        animation-timing-function:linear
    }
    
    .menu--content {
        position: fixed;
        z-index: 12;
        height: 100vh;
        width:100vw;
        top:0px;
        padding:6%;
        left: 0px;
    }
    
    .menu--list {
        position: absolute;
        top: 35.5%;
    }
    
    .menu--item {
        padding-bottom: 8%;
        cursor: pointer;
    }
    
    .menu--item:hover p{
        color:#959595
    }
    
    .menu--item p {
        font-weight: 500;
        font-size: 700%;
    }

    .menu--icon:hover {
        padding: 25px;
        top: 65px;
        right: 65px;
        animation: grow 0.2s
    }
    
    @keyframes reveal {
        0% {
            padding: 25px;
            top: 85px;
            right: 85px;
        }
        45% {
            padding: 450px;
            top: -360px;
            right: -360px;
        }
        100% {
            padding: 2100px;
            top: -2010px;
            right: -2010px;
        }
    } 
    
    @keyframes hide {
        0% {
            padding: 2100px;
            top: -2010px;
            right: -2010px;
        }
        55% {
            padding: 1400px;
            top: -1310px;
            right: -1310px;
        }
        100% {
            padding: 25px;
            top: 90px;
            right: 90px;
        }
    }  

    @keyframes grow {
        0% {
            padding:20px;
            top:70px;
            right:70px;
        }
        100% {
            padding: 25px;
            top: 65px;
            right: 65px;
        }
    }
    
    @keyframes growOut {
        
        0% {
            padding: 25px;
            top: 65px;
            right: 65px;
        }
        100% {
            padding:20px;
            top:70px;
            right:70px;
        }
    }
}

/* laptops height 710 and less*/
@media only screen and (min-width: 1200px) and (max-height: 710px) and (orientation:landscape) {
    .menu--item p{
        font-size: 640%;
    }
}

/* laptops height 690 and less*/
@media only screen and (min-width: 1200px) and (max-height: 690px) and (orientation:landscape) {
    .menu--item p{
        font-size: 570%;
    }
}

