.video--content .video--text {
    position: absolute;
    left: 7%;
    bottom: 10%;
    opacity: 0;
    transition: opacity 0.6s linear;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.video--item {
    border: 0px solid transparent;
    overflow: hidden;
    display:block;
    position: absolute;
    z-index: 1;
    background-color: transparent;
    transition: border 0.13s linear;
    -webkit-transition: border 0.13s linear;
}

.video--content {
    margin: 0px;
    overflow: hidden;
    overflow-x: hidden !important;
    display:block;
    animation: marginControlOut 0.13s linear;
    -webkit-animation: marginControlOut 0.13s linear;
}

.video--item:hover {
    border: 8px solid #d5d5d5;
    overflow: hidden;
    display: block;
    transition: border 0.13s linear;
    -webkit-transition: border 0.13s linear;
}

.video--item:hover .video--content {
    margin:-8px;
    animation: marginControl 0.13s linear;
    -webkit-animation: marginControl 0.13s linear;
}

.video--item:hover ~ .video--content .video--text {
    /* opacity: 1; */
    /* transition: opacity 0.8s linear; */
}

@keyframes fadeIn {
    0% { opacity:0 }
    100% {opacity:1 }
}

@keyframes fadeOut {
    0%  { opacity:1 }
    50%  { opacity:0.5 }
    100% { opacity:0 }
}

@keyframes borderAnimation {
    0% { border: 0px solid transparent; }
    100% {  border: 8px solid #d5d5d5; }
}

@keyframes borderAnimationOut {
    0% { border: 8px solid #d5d5d5; }
    97% {  border: 0px solid transparent; }
}

@keyframes marginControl {
    0% { margin: 0px }
    100% { margin:-8px; }
}

@keyframes marginControlOut {
    0% { margin: -8px }
    97% { margin: 0px; }
}