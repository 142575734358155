body {
    background-color: 18191a;
}

.Home--title {
    font-size: 700%
}

.Home--content {
    padding-top: 15%;
    padding-bottom: 8%;
    padding-left: 6%;
    padding-right: 12%;
    letter-spacing: 0px;
    line-height: 1.2;
}

.Home--strategy {
    padding-top: 8%;
    line-height: 1.2;
}

.Home__Title--paddingBottom-7 {
    padding-bottom: 7%;
}

.Home--feedback {
    transition: max-height 0.7s ease-out !important;
    overflow: hidden !important;
}

.carousel-padding {
    padding: 5% 10% 7% 7% !important;
}

.carousel-control-prev {
    left: 0;
    display: none !important;
}

.carousel-control-next {
    height: 0px !important;
    opacity: 1 !important;
}

.carousel-caption {
    position: unset !important;
    text-align: left !important;
}

.carousel-control-next {
    position: relative !important;
    display: flex !important;
    justify-content: flex-end !important;
    width: 100vw !important;
}

.carousel-control-prev {
    display: none;
}

.arrow__right {
    /* position: absolute; */
    /* right: 50% !important; */
    /* top: 90vh; */
    /* z-index: 1; */
    width: 85px !important;
    height: 80px !important;
    cursor: pointer;
    margin-right: 6% !important;
    margin-bottom: 12% !important;

}

.sr-only {
    display: none;
}

.widget--box {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(240, 239, 240) !important;
    border-radius: 5px;
    padding: 1.5% 2%;
    background-color: #fff;
    margin-bottom: 6%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation:portrait) {
    .Home--title {
        font-size: 300%;
        padding-bottom: 20%;
    }

    .widget--box {
        width: 225px;
        padding: 3.5% 4%;
    }

    .Home--content {
        padding-top: 67%;
        padding-bottom: 25%;
        padding-left: 7%;
        padding-right: 7%;
        line-height: 1.5;
        letter-spacing: 0px;
    }

    .widget--container {
        margin: 5vh 0;
    }

    .Home--strategy {
        font-size: 38px;
        padding-left: 7%;
        padding-top: 12%;
        padding-right: 6%;
    }

    .Home--Ideas-title {
        font-size: 23.5px;
        padding-top: 8%;
        padding-left: 2%;
    }

    .Home--Ideas-itemTitle {
        font-size: 28px;
        padding-right: 15%;
        padding-left: 2%;
        line-height: 1.5;
    }

    .Home--Ideas-itemDescription {
        font-size: 14px;
        padding-right: 6%;
        padding-left: 2%;
        line-height: 2;
    }

    .Home--Ideas-itemDescription-extraPadding {
        padding-right: 2%;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .Home--Feedback-description {
        font-size: 25px;
        line-height: 1.5;
        padding-top: 5.5%;
        padding-left: 2%;
        margin-right: 0vw;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 8%;
        font-size: 23px;
        padding-left: 2%;
    }

    .Home--Feedback-client {
        font-size: 13px;
        padding-left: 2%;
        line-height: 1.5;
    }

    .carousel-padding {
        padding: 5% 10% 7% 8.5% !important;
    }

    .arrow__right {
        float: right;
    }

    .Home--feedback {
        /* min-height: 105vh; */
    }

    .arrow__right {
        /* top: 92vh; */
        width: 75px !important;
    }
}

@media only screen and (max-width: 300px) and (orientation:portrait) {
    .Home--Feedback-description {
        font-size: 22px;
    }

    .Home--Feedback-client {
        font-size: 12px
    }
}

@media only screen and (min-width: 400px) and (max-width: 415px) and (max-height: 740px) and (orientation:portrait) {
    .Home--Feedback-description {
        font-size: 28px;
    }
}

/* iphone portrait*/
@media only screen and (width: 320px) and (height:568px) and (orientation:portrait) {
    .Home--title {
        font-size: 300%;
    }

    .Home--content {
        padding-top: 57%;

        letter-spacing: 0px;
    }

    .Home--strategy {
        font-size: 35px;
        padding-right: 3%;
    }

    .Home--Ideas-title {
        font-size: 21.5px;
    }

    .Home--Ideas-itemTitle {
        font-size: 26px;
        padding-right: 0%;
    }

    .Home--Ideas-itemDescription {
        font-size: 12px;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .paddingRight {
        padding-right: 6%;
    }

    .Home--Feedback-description {
        font-size: 21px;
        padding-right: 6%;
    }

    .Home--Feedback-name {
        font-size: 20px;
        padding-top: 5%;
    }

    .Home--Feedback-client {
        font-size: 12px;
    }

    .Home--feedback {
        /* min-height: 110vh; */
    }

    .arrow__right {
        /* top: 93vh; */
        width: 75px !important;
    }
}

/* iphone landscape*/
@media only screen and (min-width: 568px) and (min-height:320px) and (orientation:landscape) {
    .Home--title {
        font-size: 230%;
    }

    .Home--content {
        padding-top: 25%;
        padding-bottom: 6%;
        padding-right: 10%;

    }

    .Home--strategy {
        font-size: 43px;
        padding-top: 12%;
    }

    .Home--Ideas-title {
        font-size: 20px;
    }

    .Home--Ideas-itemTitle {
        font-size: 25px;
        padding-right: 50%;
        padding-bottom: 0%;
    }

    .Home--Ideas-itemDescription {
        font-size: 12px;
    }

    .p--paddingBottom-17 {
        padding-bottom: 5%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .paddingRight {
        padding-right: 0%;
    }

    .p--paddingBottom-4 {
        padding-bottom: 0%;
    }

    .Home--Feedback-description {
        font-size: 21px;
        line-height: 1.5;
        padding-top: 5.5%;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 4%;
        font-size: 18px;
    }

    .Home--Feedback-client {
        font-size: 10px;
        line-height: 0.2;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7.5% !important;
    }

    .Home--feedback {
        /* min-height: 130vh; */
    }

    .arrow__right {
        /* top: 103vh; */
        width: 75px !important;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 500px) and (orientation:portrait) {
    .Home--title {
        font-size: 300%;
    }

    .Home--content {
        padding-top: 57%;
        padding-bottom: 15%;
        padding-left: 7%;
        padding-right: 7%;
        line-height: 1.5;

        letter-spacing: 0px;
    }

    .Home--strategy {
        font-size: 38px;
        padding-left: 7%;
        padding-top: 12%;
        padding-right: 6%;
    }

    .Home--Ideas-title {
        font-size: 23.5px;
        padding-top: 8%;
        padding-left: 2%;
    }

    .Home--Ideas-itemTitle {
        font-size: 28px;
        padding-right: 15%;
        padding-left: 2%;
    }

    .Home--Ideas-itemDescription {
        font-size: 13px;
        padding-right: 9%;
        padding-left: 2%;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .Home--Feedback-description {
        font-size: 30px;
        line-height: 1.5;
        padding-top: 5.5%;
        padding-left: 2%;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 8%;
        font-size: 23px;
        padding-left: 2%;
    }

    .Home--Feedback-client {
        font-size: 13px;
        padding-left: 2%;
    }

    .arrow__right {
        float: right;
    }

    .carousel-padding {
        padding: 5% 10% 7% 8.5% !important;
    }

    .Home--feedback {
        /* min-height: 105vh; */
    }

    .arrow__right {
        /* position: absolute; */
        /* right: 50% !important; */
        /* top: 92vh; */
        /* z-index: 1; */
        width: 85px !important;
        height: 80px !important;
        cursor: pointer;
        margin-right: 6% !important;
        margin-bottom: 12% !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation:portrait) {
    .Home--title {
        font-size: 330%;
    }

    .Home--content {
        padding-top: 57%;
        padding-bottom: 15%;
        padding-left: 7%;
        padding-right: 21%;
        line-height: 1.5;

        letter-spacing: 0px;
    }

    .Home--strategy {
        font-size: 38px;
        padding-left: 7%;
        padding-top: 12%;
        padding-right: 6%;
    }

    .Home--Ideas-title {
        font-size: 23.5px;
        padding-top: 8%;
        padding-left: 2%;
    }

    .Home--Ideas-itemTitle {
        font-size: 28px;
        padding-right: 15%;
        padding-left: 2%;
    }

    .Home--Ideas-itemDescription {
        font-size: 13px;
        padding-right: 9%;
        padding-left: 2%;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .Home--Feedback-description {
        font-size: 35px;
        line-height: 1.5;
        padding-top: 5.5%;
        padding-left: 2%;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 8%;
        font-size: 23px;
        padding-left: 2%;
    }

    .Home--Feedback-client {
        font-size: 13px;
        padding-left: 2%;
    }

    .arrow__right {
        float: right;
    }

    .carousel-padding {
        padding: 5% 10% 7% 8.5% !important;
    }

    .Home--feedback {
        /* min-height: 105vh; */
    }

    .arrow__right {
        /* position: absolute; */
        /* right: 50% !important; */
        /* top: 92vh; */
        /* z-index: 1; */
        width: 85px !important;
        height: 80px !important;
        cursor: pointer;
        margin-right: 6% !important;
        margin-bottom: 12% !important;

    }
}

/* Surface duo portrait (540px to 720px) */
@media only screen and (min-width: 540px) and (max-height:720px) and (orientation:portrait) {
    .Home--title {
        font-size: 50px;
    }

    .Home--content {
        padding-top: 45%;
        padding-right: 8%;

    }

    .Home--strategy {
        font-size: 60px;
    }

    .Home--Ideas-title {
        font-size: 27px;
    }

    .Home--Ideas-itemTitle {
        font-size: 33px;
        padding-right: 25%;
    }

    .p--paddingBottom-4 {
        padding-bottom: 1%;
    }

    .Home--Ideas-itemDescription {
        font-size: 15px;
        padding-right: 12%;
    }

    .p--paddingBottom-17 {
        padding-bottom: 8%;
    }

    .Home--Feedback-description {
        font-size: 38px;
    }

    .Home--Feedback-name {
        font-size: 34px;
    }

    .Home--Feedback-client {
        font-size: 20px;
    }

    .carousel-padding {
        padding: 5% 10% 7% 8% !important;
    }

    .Home--feedback {
        /* min-height: 145vh; */
    }

    .arrow__right {
        /* top: 130vh; */
        width: 95px !important;
    }

}

/* iphone6/7/8 landscape*/
@media only screen and (min-width: 667px) and (min-height:375px) and (orientation:landscape) {
    .Home--title {
        font-size: 52px;
    }

    .Home--content {
        padding-top: 25%;
        padding-bottom: 5%;
        padding-right: 20%;

    }

    .Home--strategy {
        font-size: 51px;
        padding-top: 12%;
    }

    .Home--Ideas-title {
        font-size: 20px;
    }

    .Home--Ideas-itemTitle {
        font-size: 30px;
        padding-right: 50%;
    }

    .Home--Ideas-itemDescription {
        font-size: 13px;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .Home--Feedback-description {
        font-size: 24px;
        line-height: 1.5;
        padding-top: 5.5%;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 4%;
        font-size: 20px;
    }

    .Home--Feedback-client {
        font-size: 12px;
        line-height: 0.2;
    }

    .paddingRight {
        padding-right: 5%;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7.5% !important;
    }

    .Home--feedback {
        /* min-height: 150vh; */
    }

    .arrow__right {
        /* top: 123vh; */
        width: 75px !important;
    }
}

/* Small devices (landscape) */
@media only screen and (min-width: 600px) and (orientation:landscape) {
    .Home--title {
        font-size: 52px;
    }

    .Home--content {
        padding-top: 25%;
        padding-bottom: 8%;
        padding-right: 20%;

    }
    .Home--strategy {
        font-size: 48px;
        padding-top: 5%;
    }

    .Home--Ideas-title {
        font-size: 20px;
    }

    .Home--Ideas-itemTitle {
        font-size: 35px;
        padding-right: 35%;
        line-height: 1.3;
    }

    .Home--Ideas-itemDescription {
        font-size: 11px;
        line-height: 2;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .p--paddingBottom-4 {
        padding-bottom: 2%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .Home--Feedback-description {
        font-size: 28px;
        line-height: 1.5;
        padding-top: 5.5%;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 0%;
        font-size: 19px;
    }

    .Home--Feedback-client {
        font-size: 11px;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7.5% !important;
    }

    .Home--feedback {
        /* min-height: 155vh; */
    }

    .arrow__right {
        /* top: 130vh; */
        width: 75px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:landscape) {
    .Home--title {
        font-size: 75px;
    }

    .widget--box {
        width: 225px;
    }

    .Home--content {
        padding-top: 32%;
        padding-bottom: 8%;
        padding-right: 16%;

    }

    .Home--strategy {
        font-size: 60px;
        padding-top: 5%;
    }

    .Home--Ideas-title {
        font-size: 20px;
    }

    .Home--Ideas-itemTitle {
        font-size: 35px;
        padding-right: 0%;
    }

    .Home--Ideas-itemDescription {
        font-size: 11px;
    }

    .p--paddingBottom-17 {
        padding-bottom: 10%;
    }

    .p--paddingBottom-4 {
        padding-bottom: 2%;
    }

    .paddingtop {
        padding-top: 15%;
    }

    .Home--Feedback-description {
        font-size: 32px;
        line-height: 1.5;
        padding-top: 5.5%;
    }

    .paddingRight-0 {
        padding-right: 0%;
    }

    .Home--Feedback-name {
        padding-top: 0%;
        font-size: 19px;
    }

    .Home--Feedback-client {
        font-size: 11px;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7.5% !important;
    }

    .Home--feedback {
        /* min-height:80vw; */
    }

    .arrow__right {
        /* top: 65vw; */
        width: 75px !important;
    }
}

/* iphone x landscape*/
@media only screen and (min-width: 812px) and (min-height:375px) and (orientation:landscape) {
    .Home--title {
        font-size: 65px;
    }

    .Home--content {
        padding-top: 31%;
        padding-bottom: 6%;
        padding-right: 20%;

    }

    .Home--strategy {
        font-size: 64px;
        padding-top: 8%;
        padding-right: 7%;
    }

    .Home--Ideas-title {
        font-size: 20px;
    }

    .Home--Ideas-itemTitle {
        font-size: 37px;
        padding-right: 0%;
    }

    .Home--Feedback-description {
        font-size: 50px;
        line-height: 1.5;
    }

    .Home--Feedback-name {
        padding-top: 0%;
        font-size: 22px;
    }

    .Home--Feedback-client {
        font-size: 14px;
        line-height: 0.8;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7.5% !important;
    }

    .Home--feedback {
        /* min-height:135vw; */
    }

    .arrow__right {
        /* top: 120vw; */
        width: 75px !important;
    }
}

/*iphone 12 pro max landscape(926px and 428px) */
@media only screen and (min-width: 926px) and (min-height: 428px) and (orientation:landscape) {
    .Home--title {
        font-size: 70px;
    }

    .Home--content {
        padding-top: 30%;
        padding-bottom: 5%;
        padding-left: 5%;
        padding-right: 20%;

    }

    .Home--strategy {
        font-size: 75px;
        padding-top: 8%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .Home--Ideas-title {
        font-style: 22px;
    }

    .Home--Ideas-itemTitle {
        font-size: 37px;
        padding-right: 0%;
        line-height: 1.3;
    }

    .Home--Ideas-itemDescription {
        font-size: 12px;
        line-height: 1.7;
    }

    .Home--Feedback-description {
        font-size: 33px;
        line-height: 1.5;
    }

    .Home--Feedback-name {
        padding-top: 0%;
        font-size: 21px;
    }

    .Home--Feedback-client {
        font-size: 12px;
        line-height: 0.6;
    }

    .paddingRight {
        padding-right: 0%;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7% !important;
    }

    .Home--feedback {
        /* min-height:130vh; */
    }

    .arrow__right {
        /* top: 110vh; */
        width: 75px !important;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (orientation:landscape) {
    .Home--title {
        font-size: 70px;
    }

    .Home--content {
        padding-top: 32.25%;
        padding-bottom: 6%;
        padding-left: 7%;
        padding-right: 20%;

    }

    .Home--strategy {
        font-size: 77px;
        padding-left: 7%;
        padding-top: 8%;
    }

    .Home__Title--paddingBottom-7 {
        padding-bottom: 7%;
    }

    .Home--Ideas-title {
        font-size: 25px;
        padding-left: 1%;
    }

    .Home--Ideas-itemDescription {
        font-size: 12px;
    }

    .Home--Ideas-itemTitle {
        font-size: 38px;
        padding-right: 0%;
        padding-left: 3%;
    }

    .Home--Ideas-itemDescription {
        padding-right: 6%;
        padding-bottom: 17%;
        padding-left: 3%;
    }

    .Home--Ideas-itemDescription-extraPadding {
        padding-right: 8%;
    }

    .Home--Feedback-description {
        font-size: 50px;
        line-height: 1.5;
        padding-left: 2%;
    }

    .Home--Feedback-name {
        padding-top: 1%;
        padding-left: 2%;
        font-size: 25px;
    }

    .Home--Feedback-client {
        font-size: 15px;
        padding-left: 2%;
        line-height: 0.7;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7% !important;
    }

    .Home--feedback {
        /* min-height:105vw; */
    }

    .arrow__right {
        /* top: 90vw; */
        width: 75px !important;
    }
}

/* Surface duo landscape (1114px to 720px) */
@media only screen and (min-width: 1114px) and (max-height:720px) and (orientation:landscape) {
    .Home--title {
        font-size: 54px;
    }

    .Home--content {
        padding-top: 27%;
        padding-bottom: 10%;
        padding-right: 20%;

    }

    .Home--strategy {
        font-size: 86px;
        padding-top: 5%;
    }

    .Home--Ideas-title {
        font-style: 25px;
    }

    .Home--Ideas-itemTitle {
        font-size: 37px;
        padding-right: 15%;
    }

    .Home--Feedback-description {
        font-size: 39px;
        line-height: 1.5;
    }

    .Home--Feedback-name {
        padding-top: 2%;
        font-size: 28px;
    }

    .Home--Feedback-client {
        font-size: 16px;
        line-height: 0.8;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7% !important;
    }

    .Home--feedback {
        /* min-height:95vh; */
    }

    .arrow__right {
        /* top: 80vh; */
        width: 90px !important;
    }

}

/* ipad landscape */
@media only screen and (min-width: 1024px) and (min-height:768px) and (orientation:landscape) {
    .Home--title {
        font-size: 75px
    }

    .widget--box {
        width: 250px;
    }
    

    .Home--content {
        padding-top: 29.5%;
        padding-bottom: 10%;
        padding-right: 20%;
    }

    .Home--strategy {
        font-size: 78px;
        padding-top: 5%;
    }

    .Home--Ideas-title {
        font-style: 30px;
    }

    .Home--Ideas-itemTitle {
        font-size: 42px;
        padding-right: 8%;
    }

    .Home--Feedback-description {
        font-size: 36px;
        line-height: 1.5;
    }

    .Home--Feedback-name {
        padding-top: 2%;
        font-size: 28px;
    }

    .Home--Feedback-client {
        font-size: 18px;
        line-height: 0.8;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7% !important;
    }

    .Home--feedback {
        /* min-height:85vh; */
    }

    .arrow__right {
        /* top: 72vh; */
        width: 90px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (orientation:landscape) {
    .Home--title {
        font-size: 700%
    }

    .Home--content {
        padding-top: 20%;
        padding-bottom: 8%;
        padding-left: 6%;
        padding-right: 12%;
        letter-spacing: 0px;
        line-height: 1.2;
    }

    .Home--strategy {
        padding-top: 8.5%;
        line-height: 1.14;
        font-size: 120px;
    }

    .Home__Title--paddingBottom-7 {
        padding-bottom: 7%;
    }

    .Home--Ideas-title {
        font-size: 30px;
    }

    .Home--Ideas-itemTitle {
        font-size: 53px;
        padding-bottom: 4%;
        padding-right: 13%;
        line-height: 1.3;
    }

    .Home--Ideas-itemDescription {
        font-size: 16px;
        padding-right: 10%;
        padding-bottom: 17%;
        line-height: 2;
    }

    .paddingRight--10 {
        padding-right: 14%;
    }

    .Home--Ideas-itemDescription-extraPadding {
        padding-right: 16%;
    }

    .Home--Feedback-description {
        font-size: 51px;
        padding-top: 0%;
        padding-right: 5%;
        line-height: 1.35
    }

    .Home--Feedback-name {
        font-size: 34px;
        font-weight: normal;
    }

    .Home--Feedback-client {
        font-size: 17px;
        line-height: 0.9;
    }

    .carousel-padding {
        padding: 5% 10% 7% 7% !important;
    }

    .arrow__right {
        float: right;
    }

    .Home--feedback {
        /* min-height: 112vh; */
    }

    .arrow__right {
        /* top: 96vh; */
        width: 85px !important;
    }
}

/* iphone x landscape*/
@media only screen and (min-width: 375px) and (min-height:812px) and (orientation:portrait) {
    .Home--feedback {
        /* min-height: 85vh; */
    }

    .arrow__right {
        /* top: 73vh; */
        width: 85px !important;
    }
}

/*iphone 12 pro max portrait(428px and 926px) */
@media only screen and (min-width: 428px) and (min-height: 926px) and (orientation:portrait) {
    .Home--title {
        font-size: 300%;

    }

    .Home--content {
        padding-top: 71%;
        padding-bottom: 18%;
    }

    .Home--strategy {
        font-size: 50px;
    }

    .Home--Ideas-title {
        font-size: 27.5px;
    }

    .Home--Ideas-itemTitle {
        font-size: 30px;
        line-height: 1.5;
    }

    .paddingRight {
        padding-right: 5%;
    }

    .Home--Ideas-itemDescription {
        font-size: 16px;
        line-height: 2;
    }

    .Home--Feedback-description {
        font-size: 30px;
    }

    .Home--Feedback-name {
        font-size: 29.5px;
    }

    .Home--Feedback-client {
        font-size: 17px;
    }

    .Home--feedback {
        /* min-height: 95vh; */
    }

    .arrow__right {
        /* top: 83vh; */
        width: 85px !important;
    }
}

/*(ipad portrait, 768px and 1024px and up) */
@media only screen and (min-width: 768px) and (min-height:1024px) and (orientation:portrait) {
    .Home--title {
        font-size: 450%;
    }

    .widget--box {
        width: 225px;
    }

    .Home--content {
        padding-top: 40%;
        padding-bottom: 10%;
        padding-right: 5%;

    }

    .Home--strategy {
        padding-top: 8%;
        line-height: 1.2;
        font-size: 60px;
    }

    .Home--Ideas-title {
        font-size: 32px;
        padding-left: 0%;
    }

    .Home--Ideas-itemTitle {
        font-size: 30px;
        padding-bottom: 4%;
    }

    .Home--Ideas-itemDescription {
        padding-right: 7%;
        padding-bottom: 17%;
        font-size: 11px;
    }

    .Home--Ideas-itemDescription-extraPadding {
        padding-right: 8%;
    }

    .Home--Feedback-description {
        font-size: 27.5px;
        padding-top: 0%;
        padding: 0%
    }

    .Home--Feedback-name {
        padding-top: 3%;
        padding-left: 0%;
        font-size: 27px;
    }

    .Home--Feedback-client {
        font-size: 16px;
        padding-left: 0%;
    }

    .arrow__right {
        float: right;
    }

    .Home--feedback {
        /* min-height: 50vh; */
    }

    .arrow__right {
        /* top: 40vh; */
        width: 90px !important;
    }

}

/*(ipad pro portrait, 1024px and 1366px and up) */
@media only screen and (min-width: 1024px) and (min-height:1366px) and (orientation:portrait) {
    .Home--title {
        font-size: 550%;
    }

    .Home--content {
        padding-top: 40%;
        padding-bottom: 10%;
        padding-right: 15%;

    }

    .Home--strategy {
        padding-top: 8%;
        line-height: 1.2;
        font-size: 80px;
    }

    .Home--Ideas-title {
        font-size: 44px;
        padding-left: 0%;
    }

    .Home--Ideas-itemTitle {
        font-size: 43px;
        padding-bottom: 4%;
    }

    .Home--Ideas-itemDescription {
        padding-right: 8%;
        padding-bottom: 17%;
        font-size: 14px;
    }

    .Home--Ideas-itemDescription-extraPadding {
        padding-right: 6%;
    }

    .Home--Feedback-description {
        font-size: 37px;
        padding-top: 0%
    }

    .Home--Feedback-name {
        font-size: 27px;
        padding-top: 5%;
    }

    .Home--Feedback-client {
        font-size: 15px;
        line-height: 0.6;
    }

    .Home--feedback {
        /* min-height: 50vh; */
    }

    .arrow__right {
        /* top: 40vh; */
        width: 90px !important;
        margin-bottom: 18% !important;
    }
}