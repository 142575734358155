
.alert--popup {
    position: fixed !important;
    top:0%;
    width: 100%;
    z-index: 15;
    opacity: 1;
    animation: 1s fadeIn
}
.alert--popin {
    position: fixed !important;
    top:0%;
    z-index: 15;
    opacity: 0;
    width: 100%;
    animation: 2s fadeOut
}
.Contact--Input-padding input, .Contact--Input-padding textarea {
    font-size: 12px;
}

.Contact--Input-padding .contact--selected {
    border: 1px solid #959595 !important;
    border-width: 1px !important;
}

.Contact--Input-padding .error {
    border: 1px solid rgb(255, 0, 0) !important;
    border-width: 1px !important;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.contact--title,.contact--text,.Contact--Form-title{
    padding-left: 6%;
}
.contact-content{
    padding: 0% 6% 0% 6%;
}
/* .contact-content img {
    opacity:0.5;
    cursor: pointer;
}
.contact-content img:hover {
    opacity: 1;
} */
.display-mobile{
    padding-left: 4%;
}
.large-width{
    padding: 2% 5% 2% 3%;
}
.small-width{
    padding: 2% 5% 2% 3%;
}

input[type=text] {
    background-color: #27292B;
    color:#e2e2e2 ;
    border: 1px solid #18191a;
    outline: none;
}
input[type=email]{
    background-color: #27292B;
    color:#e2e2e2 ;
    border: 1px solid #18191a;
    outline: none;
}
textarea {
    resize: none;
}
.textarea{
    padding: 1.5% 5% 1.5% 2%;
    background-color: #27292B;
    color:#e2e2e2 ;
    border: 1px solid #18191a;
    outline: none;
    resize: none;
    height: 150px;
    width: 215px;  
}
.Contact--contact:hover{
    color: grey;
    cursor: pointer;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .Contact--Input-padding input, .Contact--Input-padding textarea {
        font-size: 16px;
    }
    .contact--title{
        font-size: 300%;
        padding-top: 66.5%;
        padding-bottom: 4%;
        line-height: 1.2;
        padding-left: 7%;
    }
    .contact--text{
        padding-left: 8%;
        padding-right: 1%;
        padding-bottom: 20%;
        font-size: 23.5px;
    }
    .display-mobile{
        display: block;
        padding-bottom: 15%;
        padding-left: 6%;
    }
    .display-lap{
        display: none;
    }
    .Contact--Form-title{
        font-size: 24px;
        padding-bottom: 5%;
        padding-left: 8%;
    }
    .contact-content{
        padding: 0% 6% 0% 6%;
        margin: 0 !important;
    }
    .large-width{
        width: 100%;
        padding: 4% 5% 4% 4%;
    }
    .small-width{
        width:100%;
        padding: 4% 5% 4% 4%;
    }
    .textarea{
        height: 150px;
        width:100%;  
    }
    .mobile--marginBottom__5{
        margin-bottom: 7%;
    }
    .Contact--contact{
        font-size: 16px;
    }
}
/* iphone portrait*/
@media only screen and (width: 320px) and (height:568px) and (orientation:portrait){
    .contact--text{
        font-size: 21.5px;
        padding-bottom: 0%;
    }
    .Contact--contact {
        font-size: 12px;
    }
    .Contact--Form-title {
        font-size: 22px;
    }
    .display-mobile {
        padding-left: 5%;
    }
    .recaptcha {
        margin-left: -12% !important;
    }
}
/* iphone landscape*/
@media only screen and (min-width: 568px) and (min-height:320px) and (orientation:landscape){
    .contact--title{
        padding-top: 25%;
        padding-bottom: 0%;
        padding-left: 8%;
        letter-spacing: 0px;
        line-height: 1.2;
        font-size: 230%;
    }
    .contact--text{
        padding-right: 0%;
        padding-left: 8%;
        padding-bottom: 11%;
        line-height: 1.8;
        letter-spacing: 0px;
        font-size: 20px;
    }
    .display-mobile{
        display: block;
        padding-left: 6.5%;
        padding-bottom: 7%;
    }
    .display-lap{
        display: none;
    }
    .Contact--Form-title{
        padding-bottom: 1%;
        padding-left: 8%;
        font-size: 25px;
    }
    .contact-content{
        padding: 0% 6% 0% 8%;
    }
    .input-marginLeft{
        margin-left: 0%;
    }
    .Contact--Input-padding{
        padding-bottom: 5%;
    }
    .large-width{
        width: 85vw;
        margin-bottom: 7%;
    }
    .small-width{
        width:85vw;
        margin-bottom: 2%;
    }
    .textarea{
        height: 150px;
        width: 98%;  
    }
    .Contact--contact{
        font-size: 13px;
    }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation:portrait){
    .contact--title{
        font-size: 300%;
        padding-top: 59%;
        padding-bottom: 4%;
        line-height: 1.2;
        padding-left: 8%;
    }
    .contact--text{
        padding-left: 8%;
        padding-right: 1%;
        padding-bottom: 20%;
        font-size: 23.5px;
    }
    .display-mobile{
        display: block;
        padding-bottom: 15%;
        padding-left: 6%;
    }
    .display-lap{
        display: none;
    }
    .Contact--Form-title{
        font-size: 24px;
        padding-bottom: 5%;
        padding-left: 8%;
    }
    .contact-content{
        padding: 0% 4% 0% 6%;
        margin: 0 !important;
    }
    .large-width{
        width: 97%;
        padding: 4% 5% 4% 4%;
    }
    .small-width{
        width:97%;
        padding: 4% 5% 4% 4%;
    }
    .textarea{
        height: 150px;
        width: 97%  
    }
    .mobile--marginBottom__5{
        margin-bottom: 7%;
    }
    .Contact--contact{
        font-size: 20px;
    }
    .col-md-6 {
        width: 100% !important;
    }
    
}
/* Surface duo portrait (540px to 720px) */
@media only screen and (min-width: 540px) and (max-height:720px) and (orientation:portrait){
    .contact--title{
        font-size: 50px;
        padding-top: 45%;
        padding-left: 8%;
    }
    .contact--text{
        font-size: 27px;
        padding-right: 10%;
        padding-bottom: 14%;
    }
    .display-mobile{
        display: block;
    }
    .display-lap{
        display: none;
    }
    .contact-content {
        padding: 0% 5% 0% 5%;
    }
    .Contact--Form-title{
        font-size: 35px;
        padding-bottom: 7%;
    }
    .mobile--marginBottom__5{
        margin-bottom: 7%;
    }
    .Contact--contact{
        font-size: 17px;
    }  
}
/* iphone6/7/8 landscape*/
@media only screen and (min-width: 667px) and (min-height:375px) and (orientation:landscape){
    .contact--title{
        padding-top: 25%;
        padding-bottom: 1%;
        padding-left: 8%;
        font-size: 230%
    }
    .contact--text{
        padding-right: 25%;
        padding-bottom: 13%;
        font-size: 20px;
    }
    .Contact--Form-title{
        font-size: 30px;
    }
    .Contact--contact{
        font-size: 12px;
    }

}
/* Small devices (landscape) */
@media only screen and (min-width: 600px) and (orientation:landscape){
    .contact--title{
        padding-top: 25%;
        padding-left: 6%;
        font-size: 36px
    }
    .contact--text{
        padding-right: 28%;
        padding-left: 6%;
        padding-bottom: 11%;
        font-size: 20px;
    }.display-mobile{
        display: block;
        padding-bottom: 15%;
        padding-left: 6%;
    }
    .display-lap{
        display: none;
    }
    .Contact--Form-title{
        font-size: 24px;
        padding-bottom: 5%;
        padding-left: 8%;
    }
    .contact-content{
        padding: 0% 4% 0% 6%;
        margin: 0 !important;
    }
    .large-width{
        width: 85vw;
        
    }
    .small-width{
        width:85vw;
        
    }
    .textarea{
        height: 150px;
        width: 85vw;
    }
    .mobile--marginBottom__5{
        margin-bottom: 5%;
    }
    .Contact--contact{
        font-size: 13px;
    }
    .Contact--Input-padding {
        padding-bottom: 0%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:landscape){
    .contact--title{
        padding-top: 29.5%;
        padding-left: 6%;
        font-size: 70px;
    }
    .contact--text{
        padding-right: 25%;
        padding-left: 6%;
        padding-bottom: 5%;
        font-size: 30px;
    }
    .display-mobile{
        display: none;
    }
    .display-lap{
        display: block;
    }
    .Contact--Form-title{
        padding-left: 6%;
        font-size: 40px;
    }
    .contact-content{
        padding: 0% 6% 0% 5%;
    }
    .Contact--Input-padding {
        padding-bottom: 2%;
    }
    .large-width{
        width: 127.5%;
    }
    .small-width{
        width:95%;
        margin-bottom: 2%;
        margin-left: 24%;
    }
    .textarea{
        height: 150px;
        width: 109%;  
    }
    .Contact--contact{
        font-size: 12px;
        padding-left: 20%;
    }
    .underline{
        height: 5px;
        width: 83px;
        margin-left: 20%;
        background-color: #fff;
    }
    
}
/* iphone x landscape*/
@media only screen and (min-width: 812px) and (min-height:375px) and (orientation:landscape){
    .contact--title{
        padding-top: 22%;
        padding-bottom: 1%;
        padding-left: 6%;
        font-size: 230%
    }
    .contact--text{
        padding-right: 35%;
        padding-bottom: 13%;
        font-size: 20px;
    }
    .Contact--Form-title{
        font-size: 28px;
    }
    .display-mobile {
        padding-left: 5%;
    }
    .Contact--contact{
        font-size: 12px;
    }
}
/*iphone 12 pro max landscape(926px and 428px) */
@media only screen and (min-width: 926px) and (min-height: 428px) and (orientation:landscape){
    .contact--title{
        padding-top: 20%;
        padding-bottom: 1%;
        padding-left: 5.5%;
        font-size: 230%
    }
    .contact--text{
        padding-right: 35%;
        padding-left: 5.5%;
        padding-bottom: 13%;
        font-size: 22px;
    }
    .Contact--Form-title{
        font-size: 28px;
        padding-left: 6%;
    }
    .display-mobile {
        padding-left: 5%;
    }
    .Contact--contact{
        font-size: 14px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (orientation:landscape){
    .contact--title{
        padding-top: 21.25%;
        padding-left: 6%;
        font-size: 400%
    }
    .contact--text{
        padding-right: 35%;
        padding-left: 6%;
        padding-bottom: 5%;
        font-size: 25px;
    }
    .display-mobile{
        display: none;
    }
    .display-lap{
        display: block;
    }
    .Contact--Form-title{
        padding-left: 6%;
        font-size: 40px;
    }
    .contact-content{
        padding: 0% 6% 0% 5%;
    }

    .large-width{
        width: 127.5%;
    }
    .small-width{
        width:95%;
        margin-bottom: 2%;
        margin-left: 24%;
    }
    .textarea{
        height: 150px;
        width: 109%;  
    }
    .Contact--contact{
        font-size: 14px;
        padding-left: 20%;
    }
    .underline{
        height: 5px;
        width: 115px;
        margin-left: 20%;
        background-color: #fff;
    }
}
/* Surface duo landscape (1114px to 720px) */
@media only screen and (min-width: 1114px) and (max-height:720px) and (orientation:landscape){
    .contact--title{
        padding-top: 25%;
        padding-left: 6%;
        font-size: 450%
    }
    .contact--text{
        padding-right: 25%;
        padding-left: 6%;
        padding-bottom: 5%;
        font-size: 25px;
    }
    .display-mobile{
        display: none;
    }
    .display-lap{
        display: block;
    }
    .Contact--Form-title{
        padding-left: 6%;
        font-size: 38px;
    }
    .contact-content{
        padding: 0% 6% 0% 6%;
    }

    .large-width{
        width: 23vw;
    }
    .small-width{
        width:15.75vw;
        margin-bottom: 2%;
        margin-left: 9%;
    }
    .textarea{
        height: 150px;
        width: 40.25vw;  
    }
    .Contact--contact{
        font-size: 14px;
        padding-left: 5%;
    }
    .underline{
        height: 5px;
        width: 83px;
        margin-left: 5%;
        background-color: #fff;
    }
}
/* ipad landscape */
@media only screen and (min-width: 1024px) and (min-height:768px) and (orientation:landscape){
    .Contact--Input-padding input, .Contact--Input-padding textarea {
        font-size: 14px;
    }
    .contact--title{
        padding-top: 29.5%;
        padding-left: 6%;
        font-size: 70px;
    }
    .contact--text{
        padding-right: 25%;
        padding-left: 6%;
        padding-bottom: 5%;
        font-size: 30px;
    }
    .display-mobile{
        display: none;
    }
    .display-lap{
        display: block;
    }
    .Contact--Form-title{
        padding-left: 6%;
        font-size: 40px;
    }
    .contact-content{
        padding: 0% 6% 0% 5%;
    }
    .Contact--Input-padding {
        padding-bottom: 2%;
    }
    .large-width{
        width: 26vw;
    }
    .small-width{
        width:19.75;
        margin-bottom: 2%;
        margin-left: 24%;
    }
    .textarea{
        height: 150px;
        width: 47.25vw;  
    }
    .Contact--contact{
        font-size: 14px;
        padding-left: 20%;
    }
    .underline{
        height: 5px;
        width: 83px;
        margin-left: 20%;
        background-color: #fff;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px)  and (orientation:landscape)  {
    .Contact--Input-padding input, .Contact--Input-padding textarea {
        font-size: 20px;
    }
    input {
        height: 140%;
        margin-bottom: 25%;
        font-size: 90%
    }
    .contact--title{
        padding-top: 18%;
        padding-bottom: 1%;
        letter-spacing: 0px;
        line-height: 1.2;
        font-size: 700%;
        padding-left: 6%;
    }
    .contact--text{
        padding-left: 6%;
        padding-right: 0%;
        padding-bottom: 13%;
        line-height: 1.8;
        letter-spacing: 0px;
        font-size: 30px;
    }
    .display-mobile{
        display: none;
    }
    .display-lap{
        display: block;
    }
    .Contact--Form-title{
        padding-bottom: 3%;
        font-size: 53px;
        padding-left: 6%;
    }
    .contact-content{
        padding: 0% 5% 0%;
    }
    .input-marginLeft{
        margin-left: 0%;
    }
    .Contact--Input-padding{
        padding-bottom: 7.5%;
    }
    .large-width{
        width: 103%;
        margin-bottom: 0%;
    }
    .small-width{
        width:75%;
        margin-left:2%;
        margin-bottom: 0%;
    }
    .textarea{
        height: 150px;
        width: 89%;  
    }
    .Contact--contact{
        font-size: 20px;
        padding-left: 0%;
    }
    .underline{
        height: 5px;
        width: 94px;
        background-color: #fff;
        margin-left: 0%;
    }
    .recaptcha {
        margin-top: -6% !important;
    }
    .mobile--marginBottom__5 {
        margin-bottom:0%
    }
    .noto--regular {
        margin-top: 1%
    }
}
/* ipad pro landscape,1366px and 1024px*/
@media only screen and (min-width: 1366px) and (min-height:1024px) and (orientation:landscape){
    .contact--title{
        padding-top: 25%;
        padding-left: 6%;
        font-size: 620%;
    }
    .contact--text{
        padding-right: 25%;
        padding-left: 6%;
        padding-bottom: 5%;
        font-size: 35px;
    }
    .display-mobile{
        display: none;
    }
    .display-lap{
        display: block;
    }
    .Contact--Form-title{
        padding-left: 6%;
        font-size: 54px;
    }
    .contact-content{
        padding: 0% 6% 0% 5%;
    }
    .large-width{
        width: 30vw;
    }
    .small-width{
        width:24vw;
        margin-bottom: 2%;
        margin-left: 24%;
    }
    .textarea{
        height: 150px;
        width: 56.25vw;  
    }
    .Contact--contact{
        font-size: 17px;
        padding-left: 25%;
    }
    .underline{
        height: 5px;
        width: 96px;
        margin-left: 25%;
        background-color: #fff;
    }
}
/*iphone 12 pro max portrait(428px and 926px) */
@media only screen and (min-width: 428px) and (min-height: 926px) and (orientation:portrait){
    .contact--title{
        font-size: 300%;
        padding-top: 72%;
        padding-bottom: 5%;
    }
    .contact--text{
        padding-bottom: 29%;
        font-size: 23.5px;
        padding-right: 8%;
    }
    .display-mobile{
        padding-bottom: 18%;
    }
    .Contact--Form-title{
        font-size: 30px;
        padding-bottom: 7%;
    }
    .Contact--contact{
        font-size: 20px;
    }
    
}
/*(ipad portrait, 768px and 1024px and up) */
@media only screen and (min-width: 768px) and (min-height:1024px) and (orientation:portrait){
    .Contact--Input-padding input, .Contact--Input-padding textarea {
        font-size: 20px;
    }
    .contact--title{
        font-size: 450%;
        padding-top: 40%;
        padding-bottom: 0%;
    }
    .contact--text{
        padding-bottom: 12%;
        font-size: 32px;
    }
    .display-mobile{
        padding-bottom: 10%;
        padding-left: 7%;
    }
    .Contact--Form-title{
        font-size: 40px;
        padding-bottom: 2%;
    }
    .Contact--Input-padding{
        padding-bottom: 5%;
    }
    .Contact--contact{
        font-size: 20px;
    }
    .large-width{
        width: 46vw;
        padding: 4% 5% 4% 4%;
    }
    .small-width{
        width:30vw;
        padding: 4% 5% 4% 4%;
        margin-left: 125%;
    }
    .textarea{
        width: 79vw;  
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50% !important;
    }
    .send--button {
        width: 80vw !important;
    }
}
/*(ipad pro portrait, 1024px and 1366px and up) */
@media only screen and (min-width: 1024px) and (min-height:1366px) and (orientation:portrait){
    .contact--title{
        font-size: 550%;
        padding-top: 40%;
        padding-bottom: 0%;
    }
    .contact--text{
        padding-bottom: 7%;
        font-size: 44px;
    }
    .display-mobile{
        padding-bottom: 5%;
        padding-left: 7%;
    }
    .Contact--Form-title{
        font-size: 46px;
        padding-bottom: 2%;
        padding-left: 8%;
    }
    .Contact--Input-padding{
        padding-bottom: 5%;
    }
    .Contact--contact{
        font-size: 20px;
    }
    .large-width{
        width: 43vw;
        padding: 4% 5% 4% 4%;
    }
    .small-width{
        width:27vw;
        padding: 4% 5% 4% 4%;
        margin-left: 105%;
    }
    .textarea{
        width: 72vw  
    } 
}