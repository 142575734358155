a {
    cursor: pointer;
}

.logo {
    width: 150px;
    display: inline-block;
    font-size: 125%;
    line-height: 125%;
    position: absolute;
    top:70px;
    cursor: pointer;
    left: 12vw;
    z-index: 7;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo {
        top:40px;
        font-size: 115%;
        left: 6%;
        width: 140px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .logo {
        top:40px;
        font-size: 135%;
        left: 9vw;
        width: 140px;
    }
}

/* Small devices (landscape) */
@media only screen and (min-width: 600px) and (orientation:landscape) {
    .logo {
        top:40px;
        font-size: 115%;
        left: 6%;
        width: 140px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:portrait) {
    .logo {
        top:60px;
        font-size: 145%;
        width: 155px;
        left: 7vw
    }
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:landscape) {
    .logo {
        top:40px;
        font-size: 135%;
        left: 6%;
        width: 175px;
    }
} 

/* iPad pro portrait */
@media only screen and (min-width: 992px) and (orientation:portrait) {
    .logo {
        top:6%;
        font-size: 165%;
        width: 180px;
    }
}

/* iPad pro portrait */
@media only screen and (min-width: 992px) and (orientation:landscape) {
    .logo {
        top:60px;
        left: 6%;
        font-size: 185%;
        width: 190px;
    }
}

/* laptops */
@media only screen and (min-width: 1200px) and (max-height: 1000px) {
    .logo {
        top:80px;
        left: 6%;
        font-size: 142%;
        width: 170px;
    }
}