.Work--title{
    padding-top: 14.35%;
    padding-left: 6%;
}
.selected--filter {
    color: white !important;
}
.Work--filter{
    padding-left: 6%;
    padding-right: 50%;
    padding-bottom: 2%;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation:portrait) {
    .Work--title{
        font-size: 300%;
        padding-top: 65%;
        padding-left: 7%;
        padding-bottom: 0%;
    } 
    .Work--filter{
        font-size: 23.5px;
        padding-top: 1%;
        padding-left: 7%;
        padding-right: 1%;
        padding-bottom: 19%;
    }
}
/*iphone 12 pro max portrait(428px and 926px) */
@media only screen and (min-width: 428px) and (min-height: 926px) and (orientation:portrait){
    .Work--title{
        font-size: 300%;
        padding-top: 71%;
        padding-bottom: 2%;
    } 
    .Work--filter{
        font-size: 23.5px;
        padding-right: 8%;
        padding-bottom: 21%;
    }
}
/* iphone portrait*/
@media only screen and (width: 320px) and (height:568px) and (orientation:portrait){
    .Work--title{
        font-size: 300%;
        padding-top: 57%;
        padding-bottom: 0%;
    } 
    .Work--filter{
        font-size: 22px;
        letter-spacing:0px;
        padding-right: 2%;
        padding-bottom: 10%;
    }
}
/* iphone landscape*/
@media only screen and (min-width: 568px) and (min-height:320px) and (orientation:landscape){
    .Work--title{
        padding-top: 25%;
        font-size: 230%;
        margin-bottom: 0%;
        padding-bottom: 1%;
    }
    .Work--filter{
        font-size: 20px;
        padding-top: 0%;
        padding-right: 20%;
        padding-bottom: 8%;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation:portrait){
    .Work--title{
        font-size: 300%;
        padding-top: 57%;
        padding-left: 7%;
        padding-bottom: 0%;
    } 
    .Work--filter{
        font-size: 26.5px;
        padding-top: 1%;
        padding-left: 7%;
        padding-right: 1%;
        padding-bottom: 19%;
    }
}
/* Surface duo portrait (540px to 720px) */
@media only screen and (min-width: 540px) and (max-height:720px) and (orientation:portrait){
    .Work--title{
        padding-top: 45%;
        font-size: 50px;
        margin-bottom: 0%;
    }
    .Work--filter {
        font-size: 27px;
        padding-bottom: 10%;
        padding-right: 34%;
        padding-top: 3%;
    }
}
/* iphone6/7/8 landscape*/
@media only screen and (min-width: 667px) and (min-height:375px) and (orientation:landscape){
    .Work--title{
        padding-top: 25%;
        margin-bottom: 1%;
        padding-bottom: 1%;
        font-size: 230%;
    }
    .Work--filter{
        font-size: 20px;
        padding-bottom: 8%;
        padding-right: 25%;
    }
}
/* Small devices (landscape) */
@media only screen and (min-width: 600px) and (orientation:landscape){
    .Work--title{
        padding-top: 25%;
        font-size: 36px;
    }
    .Work--filter{
        font-size: 20px;
        padding-bottom: 3%;
        padding-right: 28%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation:landscape){
    .Work--title{
        padding-top: 32%;
        font-size: 50px;
    }
    .Work--filter{
        font-size: 20px;
        padding-bottom: 3%;
        padding-right: 40%;
    }
}
/* iphone x landscape*/
@media only screen and (min-width: 812px) and (min-height:375px) and (orientation:landscape){
    .Work--title{
    padding-top: 22%;
    font-size: 230%;
    }
    .Work--filter{
        font-size: 20px;
        padding-bottom: 3%;
        padding-right: 45%;
    }
}
/*iphone 12 pro max landscape(926px and 428px) */
@media only screen and (min-width: 926px) and (min-height: 428px) and (orientation:landscape){
    .Work--title{
        padding-top: 20%;
        padding-bottom: 1%;
        font-size: 300%;
    }
    .Work--filter{
        font-size: 22px;
        padding-bottom: 3%;
        padding-right: 45%;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (orientation:landscape) {
    .Work--title{
        padding-top: 21.25%;
        font-size: 400%;
    }
    .Work--filter{
        font-size: 25px;
        padding-left: 6%;
        padding-right: 40%;
    }
  }
/* Surface duo landscape (1114px to 720px) */
@media only screen and (min-width: 1114px) and (max-height:720px) and (orientation:landscape){
    .Work--title{
        padding-top: 27%;
        font-size: 450%;
        }
        .Work--filter{
            font-size: 25px;
            padding-bottom: 3%;
            padding-right: 45%;
        }
}
/* ipad landscape */
@media only screen and (min-width: 1024px) and (min-height:768px) and (orientation:landscape){
    .Work--title{
        padding-top: 29.5%;
        padding-left: 7%;
        padding-bottom: 2%;
        font-size: 70px;
    }
    .Work--filter{
        font-size: 30px;
        padding-left: 7%;
        padding-right: 35%;
    }
    
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px)  and (orientation:landscape) {
    .Work--title{
        padding-top: 20%;
        padding-left: 6%;
        line-height: 1.2;
        font-size: 700%;
    }
    .Work--filter{
        padding-left: 6%;
        padding-right: 50%;
        padding-bottom: 23%;
        font-size: 32px;
    }
    .filter--hover:hover{
        color: #fff;
        cursor: pointer;
    }
}
/* ipad pro landscape,1366px and 1024px*/
@media only screen and (min-width: 1366px) and (min-height:1024px) and (orientation:landscape){
    .Work--title{
        padding-top: 25%;
        padding-right: 18%;
        padding-bottom: 2%;
        font-size: 620%;
    }
    .Work--filter{
        font-size: 35px;
        padding-right: 35%;
    }
    .filter--hover:hover{
        color: grey;
        cursor: pointer;
    }
  }

/*(ipad portrait, 768px and 1024px and up) */
@media only screen and (min-width: 768px) and (min-height:1024px) and (orientation:portrait) {
    
    .Work--title{
        padding-top: 40%;
        font-size: 450%;
    }
    .Work--filter{
        font-size: 32px;
        padding-right: 14%;
        padding-bottom: 10%;
    }
    
}
/*(ipad pro portrait, 1024px and 1366px and up) */
@media only screen and (min-width: 1024px) and (min-height:1366px) and (orientation:portrait) {
    .Work--title{
        padding-top: 40%;
        font-size: 550%;
    }
    .Work--filter{
        font-size: 44px;
        padding-right: 14%;
        padding-bottom: 10%;
    }
}
